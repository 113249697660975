#request-detail {
    #request-detial-list {
        .rdl-label {
            font-family: "Open Sans", sans-serif;
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 16px;
            letter-spacing: 0.08em;
            text-transform: uppercase;
            color: #666;
        }

        .rdl-value {
            font-family: "Open Sans", sans-serif;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 22px;
            color: #000000;

            &.request-id {
                color: #29b5dc;
            }
        }

        .email-link {
            font-family: "Open Sans", sans-serif;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 22px;
            color: #0a6cff;
        }
    }

    #request-preview {
        img {
            max-width: 400px;
        }
    }

    .alert-banner {
        width: 100%;
        margin: auto;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        padding: 8px 12px;
        border-radius: 4px;

        i {
            font-size: 24px;
            margin-right: 8px;
        }

        span.alert-text {
            flex-grow: 1;
            font-family: "Open Sans", sans-serif;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 16px;
            color: #000000;
        }

        &.info {
            background: #eff5ff;

            i {
                color: #0a6cff;
            }
        }

        &.warning {
            background: #f9efe0;

            i {
                color: #ff9d0a;
            }
        }
    }

    .btn-view-pdf {
        color: white;
        background-color: opacify($color: $gogoprint-pink, $amount: 0.8);
        transition: all 0.3s ease-in-out;

        &:hover {
            background-color: $gogoprint-pink;
        }
    }

    .btn-change-pdf {
        color: white;
        background-color: rgba($color: #000000, $alpha: 0.6);
        transition: all 0.3s ease-in-out;

        &:hover {
            background-color: #000;
        }
    }

    .preview-placeholder {
        background-color: #fff;
        border-radius: 4px;
        width: 100%;
        height: 0;
        padding-bottom: 100%;

        i {
            font-size: 4em;
            color: #e7e7e7;
            display: initial;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
        }
    }

    .loading {
        position: relative;
        min-height: 560px;

        span {
            position: absolute;
            top: 50%;
            left: 50%;
        }
    }

    .request-detail-banner {
        margin: 23px -15px;
        height: 110px;
        background: #f0fbff;
        display: flex;
        justify-content: center;
        flex-direction: column;

        &>div {
            margin: 0 60px;

            .request-id-text {
                color: #2bace2;
            }

            .request-status-text {
                span {
                    color: #e84fa5;
                }
            }
        }
    }

    .request-preview {
        padding: 42px;
        border: solid 1px #f1f1f1;

        img {
            width: 520px;
            margin: 1rem 0;
            box-shadow: 0 3px 6px 3px rgb(0 0 0 / 5%);
        }

        .ggp-btn-blue {
            margin: 20px 0;
            display: block;
        }

        .contact-staff {
            border: solid 1px #bde8f2;
            background: #f0fbff;
            color: #24b3da;
            padding: 12px 43px;
            margin: 22px 0;
        }

        .shipping-address-section {
            .label {
                color: #e84fa5;
            }

            .address {
                border: solid 1px #d8d8d8;
                background: #f7f7f7;
                padding: 22px 42px;
                margin: 8px 0;
            }
        }
    }
}


.edit-submit-btn {
    min-width: 110px !important;
    background: #29b5dc !important;
    border: none !important;

    &:hover{
        background: #0ca4ce !important;
    }
}