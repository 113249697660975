.rm-btn-default {
    background: #c1c1c1 !important;
    color: white !important;
}

.rm-btn-active {
    background: crimson !important;
    color: white !important;
}

.team-section-header h3 {
    margin: 0px;
    font-size: 22px;
    font-weight: 600;
    text-align: left;
}

.toolbar {
    @include media("<=768px") {
        height: auto !important;
        margin-bottom: 8px !important;
    }

    .text-right {
        @include media("<=768px") {
            text-align: left !important;
            width: 100%;
            margin: 16px auto;
        }
    }
}

.edit-btn-cont {
    width: 80px !important;
    text-align: center;

    .btn-edit {
        font-size: 16px;
        background: transparent !important;
        padding: 0px !important;
        border: none !important;
        color: #aaa !important;

        &:hover {
            color: #555 !important;
        }
    }
}

.is-active-toggle {
    display: flex;
    align-items: center;
    justify-content: center;

    label.vue-js-switch {
        top: 3px;
        margin-bottom: auto !important;
    }
}

.header-reset-cred {
    width: 100%;
    margin: auto;
    text-align: left;
    font-family: "Open Sans", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
    color: #000000;
}

.sub-reset-cred {
    width: 100%;
    margin: auto;
    text-align: left;
    font-family: "Open Sans", sans-serif;
    font-style: normal;
    font-size: 12px;
    line-height: 16px;
    color: #666;
    -webkit-line-clamp: 2;

    span {
        color: $gogoprint-pink;
        text-decoration: underline;
    }
}

.suggestedPassword {
    width: 100%;
    margin: auto;
    text-align: left;
    font-family: "Open Sans", sans-serif;
    font-style: normal;
    font-size: 12px;
    line-height: 14px;
    color: #666;

    span {
        font-family: Courier New;
        font-style: normal;
        font-weight: bold;
        letter-spacing: 0.05em;
        text-transform: capitalize;
        font-feature-settings: "pnum" on, "lnum" on;
        cursor: pointer;
        transition: all 0.2s ease-in-out;
        background: #f8f8f8;

        &:hover {
            color: $gogoprint-blue;
            text-decoration: underline;
        }
    }
}


//  main-loader
.main-progress{
    position: fixed;
    background-color: #ffffff90;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 8888;
}