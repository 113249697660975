#order-form {
    thead {
        background: #2bace2;
        color: #ffff;
    }

    tbody {
        border: solid #f1f1f1 1px;
    }

    .filter {
        padding: 1em 0;
    }

    .toolbar {
        .remove-link {
            color: #2bace2;
            text-decoration: none;
        }
    }

    h3.page_header {
        width: 100%;
        margin: auto auto 4px auto;
        font-size: 27px;
        font-weight: 600;
        text-align: left;
    }

    .form-section {
        margin: 14px auto;

        .form-inline{
            display: flex;
            flex-flow: row wrap;
            align-items: center !important;
            justify-content: center !important;
        }

        input:focus,
        textarea:focus,
        select:focus {
            border-color: #2bace2;
            outline: none 0;
        }

        .form-input-area {
            margin-bottom: 93px;
            height: 709px;
        }

        .form-section-header {
            color: #2bace2;
            font-size: 18px;
            font-weight: 600;
            font-style: normal;
        }

        hr {
            margin: 16px 0;
            border-top: 1px solid #2bace2;
        }

        .form-field {
            padding: 20px 0 0 0;
            border-bottom: 1px solid #ddd;
            overflow: auto;
        }

        .confirm-data-container {
            width: 100%;

            .confirm-data {
                margin: 24px 0;

                > label {
                    justify-content: start;
                }
            }
        }

        .form-row {
            height: aut;
            margin: 15px 0;

            .form-label {
                font-size: 16px;
                color: #666666;
                line-height: 18px;

                @include media("<768px") {
                    margin: 4px auto;
                }
            }

            input {
                @include media("<768px") {
                    width: 100% !important;
                    margin: auto;
                }
            }
        }

        .preview-btn,
        .continue-btn {
            flex-grow: 1;
            margin: 4px;
        }

        .preview-btn {
            text-align: center;
            background: #e84fa5;
            border: none;
        }

        .preview-container {
            min-height: 560px;
        }

        .preview-img {
            max-width: 95%;
            max-height: 560px
        }

        .page-button-container {
            height: auto;
            padding: 16px 8px;

            .btn-page {
                height: 36px !important;
                min-width: 36px !important;
                padding: 8px;
                border-radius: 36px;
                outline: none !important;
                box-shadow: none !important;
                border: none !important;

                font-family: "Open Sans", sans-serif;
                font-size: 14px;

                display: flex;
                align-items: center;
                justify-content: center;

                span.label-page {
                    margin-right: 4px;
                    display: none;
                }

                &.bp-normal {
                    background: rgba(0, 0, 0, 0.04);
                    color: #666;

                    :hover {
                        background: rgba(0, 0, 0, 0.09);
                    }
                }

                &.bp-active {
                    background: rgba(232, 79, 165, 0.07);
                    color: #666;
                    min-width: 72px !important;

                    span.label-page {
                        display: block;
                    }

                    span:nth-child(2) {
                        color: #e84fa5;
                        font-weight: 800;
                    }

                    :hover {
                        background: rgba(0, 0, 0, 0.09);
                    }
                }
            }
        }

        .loading {
            position: relative;
            min-height: 560px;

            span {
                position: absolute;
                top: 50%;
                left: 50%;
            }
        }
    }

    .success-view {
        text-align: center;

        i {
            font-size: 50px;
        }

        li {
            list-style-type: none;
        }
    }
}
