.request_section {
    background: #fff;
    padding: 16px;

    .header {
        width: 100%;
        padding-top: 8px;
        margin: auto auto 8px auto;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .header-title-content {
            flex-grow: 1;
            margin: auto;
            display: flex;
            align-items: center;
            justify-content: flex-start;

            h3 {
                margin: 0px;
                font-size: 22px;
                font-weight: 600;
                text-align: left;
            }

            .total-requests {
                font-size: 14px;
                padding: 6px 12px;
                border-radius: 36px;
                font-weight: 400;
                margin-left: 16px;
                min-height: 36px;
                background: rgba(232, 79, 165, 0.07);
                flex: none;
                color: $gogoprint-light-grey-text;

                span {
                    font-weight: 600;
                    color: #e84fa5;
                    margin-right: 2px;
                }
            }
        }

        .view-all {
            color: #68a8d9;
            font-size: 14px;
            font-weight: 600;
            margin-right: 4px;
        }
    }

    div:focus {
        outline: none !important;
    }

    .btn-add-to-cart {
        margin-left: 8px;
        border: 2px solid $gogoprint-blue !important;
    }

    .ggp-accordion {
        .card {
            border: none !important;
        }

        .table {
            margin-bottom: 0px !important;
        }

        a {
            color: #2bace2 !important;
        }

        .when-closed,
        .when-open {
            display: flex;
            align-items: center;
            justify-content: flex-end;

            @include media("<=768px") {
                position: absolute !important;
                top: 12px;
                right: 4px;
            }
        }

        .accordion-chevron {
            font-size: 24px;
            color: $gogoprint-light-grey;
        }

        .when-open .accordion-chevron {
            color: $gogoprint-blue;
        }

        .request-count {
            font-weight: 800;
            color: #e84fa5;
        }

        th,
        .main-text {
            font-weight: normal !important;
            font-size: 16px;
        }

        .table-container {
            padding: 0;

            .table-active td {
                background: #ffffff !important;
            }

            .table-hover tbody tr:hover {
                background: #f2fbfe !important;
            }

            .fa.fa-check-square {
                color: $gogoprint-blue;
            }
        }

        thead {
            background: #fafafa;
        }

        .collapsed>.when-open,
        .not-collapsed>.when-closed {
            display: none;
        }

        .ggp-accordion-div {
            width: 100%;
            margin: 2px auto;
            background: #fcfcfc !important;
            height: 64px;
            display: flex;
            align-items: center;
            padding: 0px 8px;

            @include media("<=768px") {
                height: auto;
                padding: 8px 8px 16px 8px;
            }

            .address-text {
                text-transform: capitalize;
                font-weight: 600 !important;
                color: #000;
                display: flex;
                align-items: center;
                font-size: 16px;

                @include media("<=768px") {
                    padding: 8px 8px 4px 8px;
                }

                i {
                    font-size: 24px;
                    margin-right: 4px;
                }
            }
        }
    }
}

#reject-request-modal {
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: normal;
    text-align: left;

    .modal-dialog {
        max-width: 420px;
    }

    .request-ids {
        width: 100%;
        margin: auto;
        font-size: 14px;
        line-height: 16px;
        color: #666666;

        .sr-id {
            text-decoration-line: underline;
            font-weight: 600;
            color: $gogoprint-blue;
        }
    }

    .custom-control-label {
        font-size: 14px;
    }

    .err-invalid-rejection{
        font-size: 12px;
        line-height: 16px;
    }
}
