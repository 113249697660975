#login-page {
    width: 100%;
    margin: auto;
    box-sizing: border-box;
    height: 100vh;
    background-color: #f9f9f9;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .login-header-cont,
    .absolute-header-cont {
        display: flex;
        align-items: center;
        justify-content: space-between;

        position: fixed;
        left: 0;
        right: 0;
        top: 0;

        background-color: white;
        box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.05);
        max-height: 80px;
        height: auto;
        padding: 12px 24px;
        z-index: 9999;

        .tag-portal {
            font-family: Open Sans;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 19px;

            letter-spacing: 0.08em;
            text-transform: uppercase;

            color: #999999;
            flex: none;
        }
    }

    .login-content-cont,
    .absolute-content-cont {
        width: 100%;
        margin: auto;
        margin-top: 80px;

        flex-grow: 1;
        display: flex;
        align-items: center;
        justify-content: center;

        .form-container {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            flex-direction: column;

            width: 100%;
            margin: auto;
            max-width: 360px;
            min-height: 418px;
            padding: 8px;

            @include media("<=360px") {
                width: 98%;
            }

            .form-cont-header {
                width: 100%;
                margin: 0px auto;
                text-align: center;

                font-family: "Open Sans", sans-serif;
                font-style: normal;
                font-weight: bold;
                font-size: 30px;
                line-height: 41px;

                color: #000000;

                @include media("<=512px") {
                    font-size: 24px;
                    line-height: 30px;
                }
            }

            .tab-container {
                width: 100%;
                margin: 32px auto 16px auto;
                display: flex;
                align-items: center;
                justify-content: center;

                .selection-tab {
                    min-width: 116px;
                    margin: auto 8px;
                    min-height: 36px;
                    background: #ffffff;
                    border: 1px solid rgba(206, 212, 218, 0.19);
                    box-sizing: border-box;
                    box-shadow: 0px 0px 4px #fbfbfb;
                    border-radius: 4px;

                    font-family: "Open Sans", sans-serif;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 12px;
                    line-height: 16px;
                    text-align: center;
                    line-height: 36px;
                    border: 2px solid transparent;

                    color: #444444;
                    transition: all 0.2s ease-in-out;
                    cursor: pointer;

                    &.active-tab {
                        background: rgba(41, 181, 220, 0.04);
                        color: #29b5dc;
                    }

                    &.active-tab:hover {
                        background: rgba(41, 181, 220, 0.082);
                    }

                    &:hover {
                        border: 2px solid #29b5dc;
                    }
                }
            }

            .tab-content-cont {
                width: 100%;
                margin: 24px auto auto auto;
                display: flex;
                flex-direction: column;

                .tab-content {
                    display: none;

                    &.tc-active {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        flex-direction: column;
                    }

                    form {
                        width: 100%;
                        margin: auto;

                        .login-select-header {
                            flex-grow: 1;
                            font-family: "Open Sans", sans-serif;
                            font-style: normal;
                            font-weight: 600;
                            font-size: 14px;
                            line-height: 19px;
                            color: #000000;
                            text-align: left;
                        }

                        input {
                            height: auto;
                            padding: 8px !important;
                        }

                        .link-forgot-pass {
                            text-align: right;
                            font-family: "Open Sans", sans-serif;
                            font-style: normal;
                            font-weight: 600;
                            font-size: 12px;
                            line-height: 16px;
                            margin-bottom: 4px;

                            color: #269bbc;

                            transition: all 0.2s ease-in-out;

                            &:hover {
                                color: #1c7791;
                            }
                        }
                    }

                    .login-button {
                        width: 100%;
                        margin: auto;
                        padding: 10px;
                        text-align: center;
                        border-radius: 4px;
                        margin-top: 32px;
                        transition: all 0.2s ease-in-out;
                        text-decoration: none !important;

                        &:hover {
                            background: #c71b7c !important;
                        }
                    }
                }
            }
        }
    }

    .login-footer, 
    .absolute-footer {
        width: 100%;
        margin: auto;
        min-height: 64px;
        padding: 16px 24px;
        border-top: 1px solid #e8e8e8;

        display: flex;
        align-items: center;
        justify-content: space-between;

        @include media("<=700px") {
            justify-content: center;
            flex-direction: column-reverse;
        }

        .copyright {
            font-family: "Open Sans", sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            color: #666;
            text-align: left;

            @include media("<=700px") {
                text-align: center;
                margin-top: 8px;
            }
        }

        .footer-links {
            list-style-type: none;
            margin: 0;
            padding: 0;

            display: flex;
            align-items: center;
            justify-content: center;

            li {
                a {
                    text-decoration: none !important;
                    font-family: "Open Sans", sans-serif;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 12px;
                    line-height: 16px;
                    margin-left: 12px;

                    color: #666666;

                    transition: all 0.2s ease-in-out;

                    &:hover {
                        color: #29b5dc;
                    }

                    @include media("<=700px") {
                        margin: 8px;
                    }
                }
            }
        }
    }
}
