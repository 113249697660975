.team-invite-section {

    .toast{
        opacity: 1;
    }
    border: solid 1px #D8D8D8;
    background:#ffffff;
    padding: 43px;
    .loading{
        position:relative;
        min-height:560px;
        span{
            position:absolute;
            top:50%;
            left:50%;
        }
    }

    .success-view{
        text-align: center;
        i{
            font-size:50px;
        }
        li{
            list-style-type: none;
        }
    }
    .header{
        font-size:32px;
        font-weight:bold;
    }

    .sub-header{
        font-size:20px;
        font-weight:bold;
    }

    .text-area-container{
        > div{
            width: 975px;
        }

        .invite-button-section{
            margin: 40px 0;
        }
        .text-area{
            width:100%;
            height: 120px;
            border: solid 1px #D8D8D8;
            cursor: text;
            .ti-input{
                border:none;
                input{
                    font-size: 14px;
                    min-height: 40px;
                }
            }
            .vue-tags-input{
                max-width: unset;
            }
            .ti-tag{
                height: 40px;
                background: #F7F7F7;
                color: #333333;
                border: 1px solid #D8D8D8;
            }
            .ti-content{
                font-size: 14px;
            }
        }

        .personal-message-section{
            margin-top:32px;
        }
    }

}
