#profile-edit {
    .profile-edit-container {
        max-width: 540px;
        width: 100%;

        .pe-header {
            border-radius: 8px;
            padding: 16px 0px;


            .btn-back {
                border: none !important;
                background: none !important;
                padding: 0px;
                font-size: 16px;
                width: 32px;
                height: 32px;
            }

            h5 {
                flex-grow: 1;
                font-family: 'Open Sans', sans-serif;
                font-style: normal;
                font-weight: bold;
                font-size: 22px;
                line-height: 30px;
                color: #000000;
                text-align: left;
                margin: auto;
            }
        }


    }

    .loading-cont {
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        box-sizing: border-box;
        background: rgba($color: #ffffff, $alpha: 0.5);
    }
}

.pe-section-label {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    width: 100%;
    color: $gogoprint-pink !important;
    margin: 4px auto 16px auto;
    text-align: left;
    color: #000000;
    text-transform: uppercase;
    letter-spacing: 1px;
}


.profile-form,
.credentials-form {
    background: #fff;
    box-shadow: 0px 4px 4px 0px rgba(225, 225, 225, 0.44);
    border-radius: 8px;
    padding: 16px;

    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    line-height: 19px;

    input {
        font-size: 14px !important;
    }
}
