#dashboard {
    .main-content {
        .main-header {
            position: relative;
            width: 100%;
            margin: 30px auto auto auto;

            & > div.header-text {
                font-size: 30px;
                font-weight: 900;
                font-family: "Open Sans", sans-serif;

                @include media("<=556px") {
                    text-align: center;
                }

                @include media("<=375px") {
                    font-size: 22px;
                }
            }

            p {
                text-align: left;
                @include media("<=556px") {
                    text-align: center !important;
                }

                
                @include media("<=375px") {
                    font-size: 14px;
                }
            }

            small {
                font-style: normal;
                font-weight: normal;
                font-size: 17px;
                line-height: 23px;
                color: #666666;

                a {
                    color: $gogoprint-pink;
                    font-weight: 600;
                }
            }
        }

        .main-header + hr {
            width: 100%;
            margin: 30px auto;
            opacity: 0.7;
        }

        .corperate_template_section {
            background: #fff;
            border-radius: 4px;
            font-family: "Open Sans", sans-serif;

            .header {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 8px;

                .header-content {
                    flex-grow: 1;
                    margin: auto;
                    display: flex;
                    align-items: flex-start;
                    justify-content: flex-start;
                    flex-direction: column;

                    @include media("<=556px") {
                        width: 100%;
                    }
    

                    h3 {
                        width: 100%;
                        margin: auto auto 4px auto;
                        font-size: 22px;
                        font-weight: 600;
                        text-align: left;
                    }

                    p {
                        width: 100%;
                        margin: auto;
                        font-size: 14px;
                        font-weight: 300;
                        color: #666;
                        text-align: left;
                    }
                }

                .view-all {
                    color: #68a8d9;
                    font-size: 14px;
                    font-weight: 600;
                    margin-right: 4px;
                }
            }

            .corporate_template_list {
                display: flex;
                flex-wrap: wrap;
                align-items: flex-start;
                justify-content: flex-start;
            }
        }
    }
}
